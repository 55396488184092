:root {
  --primaryBackground: #D0F9BC;
  --primaryLight: #88EA57;
  --primaryDarkmode: #56CB1D;
  --primary: #254441;
  --primaryDark: #162B29;

  --successLight: #FFE5E5;
  --successBackground: #FF9999;
  --successDarkmode: #FF4C4C;
  --success: #E50000;
  --successDark: #990000;

  --grayBackground: #F7F7FC;
  --grayInput: #EFF0F6;
  --grayLine: #D9DBE9;
  --grayPlaceholder: #A0A3BD;
  --grayLabel: #6E7191;
  --grayBody: #4E4B66;
  --grayAsh: #262338;
  --grayOffBlack: #14142B;

  --yarl__portal_zindex: 99999;
}

.primaryBackground {
  color: var(--primaryBackground);
}
.primaryLight {
  color: var(--primaryLight);
}
.primaryDarkmode {
  color: var(--primaryDarkmode);
}
.primary {
  color: var(--primary);
}
.primaryDark {
  color: var(--primaryDark);
}
.successLight {
  color: var(--successLight);
}
.successBackground {
  color: var(--successBackground);
}
.successDarkmode {
  color: var(--successDarkmode);
}
.success {
  color: var(--success);
}
.successDark {
  color: var(--successDark);
}
.grayInput {
  color: var(--grayInput);
}
.grayLine {
  color: var(--grayLine);
}
.grayPlaceholder {
  color: var(--grayPlaceholder);
}
.grayLabel {
  color: var(--grayLabel);
}
.grayBody {
  color: var(--grayBody);
}
.grayAsh {
  color: var(--grayAsh);
}
.grayOffBlack {
  color: var(--grayOffBlack);
}

.border_success {
  border: 1px solid var(--success);
}

.bg_white {
  background-color: #FFF;
}
.bg_primary_white {
  color: #FFF;
  background-color: var(--primary);
}
.bg_primaryDarkmode_white {
  color: #FFF;
  background-color: var(--primaryDarkmode);
}
.bg_grayBackground {
  background-color: var(--grayBackground);
}
.bg_successDarkmode_white {
  color: #FFF;
  background-color: var(--successDarkmode);
}

.mh {
  min-height: calc( 100vh - 72px );
}

.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}

input {
  outline: none;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.75px;
  font-family: Helvetica, sans-serif;

  -webkit-font-smoothing: antialiased;
}

input, button {
  letter-spacing: 0.75px;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
}

* {
  box-sizing: border-box;
  /* color: var(--grayAsh); */
}

/* @font-face {
  font-family: noto-sans-tc; 
  src: url('/fonts/NotoSansTC-Regular.otf');
}
@font-face {
  font-family: noto-sans-tc; 
  src: url('/fonts/NotoSansTC-Bold.otf');
  font-weight: 700;
} */
